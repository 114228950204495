:root {
	--ion-color-status-grey: #9a9996;
	--ion-color-status-grey-rgb: 154,153,150;
	--ion-color-status-grey-contrast: #000000;
	--ion-color-status-grey-contrast-rgb: 0,0,0;
	--ion-color-status-grey-shade: #888784;
	--ion-color-status-grey-tint: #a4a3a1;
}

.ion-color-status-grey {
	--ion-color-base: var(--ion-color-status-grey);
	--ion-color-base-rgb: var(--ion-color-status-grey-rgb);
	--ion-color-contrast: var(--ion-color-status-grey-contrast);
	--ion-color-contrast-rgb: var(--ion-color-status-grey-contrast-rgb);
	--ion-color-shade: var(--ion-color-status-grey-shade);
	--ion-color-tint: var(--ion-color-status-grey-tint);
}

:root {
	--ion-color-status-blue: #3584e4;
	--ion-color-status-blue-rgb: 53,132,228;
	--ion-color-status-blue-contrast: #000000;
	--ion-color-status-blue-contrast-rgb: 0,0,0;
	--ion-color-status-blue-shade: #2f74c9;
	--ion-color-status-blue-tint: #4990e7;
}

.ion-color-status-blue {
	--ion-color-base: var(--ion-color-status-blue);
	--ion-color-base-rgb: var(--ion-color-status-blue-rgb);
	--ion-color-contrast: var(--ion-color-status-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-status-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-status-blue-shade);
	--ion-color-tint: var(--ion-color-status-blue-tint);
}

:root {
	--ion-color-status-orange: #ff7800;
	--ion-color-status-orange-rgb: 255,120,0;
	--ion-color-status-orange-contrast: #000000;
	--ion-color-status-orange-contrast-rgb: 0,0,0;
	--ion-color-status-orange-shade: #e06a00;
	--ion-color-status-orange-tint: #ff861a;
}

.ion-color-status-orange {
	--ion-color-base: var(--ion-color-status-orange);
	--ion-color-base-rgb: var(--ion-color-status-orange-rgb);
	--ion-color-contrast: var(--ion-color-status-orange-contrast);
	--ion-color-contrast-rgb: var(--ion-color-status-orange-contrast-rgb);
	--ion-color-shade: var(--ion-color-status-orange-shade);
	--ion-color-tint: var(--ion-color-status-orange-tint);
}

:root {
	--ion-color-status-yellow: #f6d32d;
	--ion-color-status-yellow-rgb: 246,211,45;
	--ion-color-status-yellow-contrast: #000000;
	--ion-color-status-yellow-contrast-rgb: 0,0,0;
	--ion-color-status-yellow-shade: #d8ba28;
	--ion-color-status-yellow-tint: #f7d742;
}

.ion-color-status-yellow {
	--ion-color-base: var(--ion-color-status-yellow);
	--ion-color-base-rgb: var(--ion-color-status-yellow-rgb);
	--ion-color-contrast: var(--ion-color-status-yellow-contrast);
	--ion-color-contrast-rgb: var(--ion-color-status-yellow-contrast-rgb);
	--ion-color-shade: var(--ion-color-status-yellow-shade);
	--ion-color-tint: var(--ion-color-status-yellow-tint);
}

:root {
	--ion-color-status-green: #33d17a;
	--ion-color-status-green-rgb: 51,209,122;
	--ion-color-status-green-contrast: #000000;
	--ion-color-status-green-contrast-rgb: 0,0,0;
	--ion-color-status-green-shade: #2db86b;
	--ion-color-status-green-tint: #47d687;
}

.ion-color-status-green {
	--ion-color-base: var(--ion-color-status-green);
	--ion-color-base-rgb: var(--ion-color-status-green-rgb);
	--ion-color-contrast: var(--ion-color-status-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-status-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-status-green-shade);
	--ion-color-tint: var(--ion-color-status-green-tint);
}
